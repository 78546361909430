<template>
  <div class="impact">
    <div class="container">
      <div
        class="content funded"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="880"
      >
        <div class="content-inner">
                  <h2>Funds collected so far</h2>
        <div class="total">
          <span class="currency">VND</span
          ><span class="number">584,588,000</span>
        </div>
        <div class="label">
          <p>The whole amount was aggregated converting into the gift bags.</p>
        </div>
        <div class="total last">
          <span class="number">2,550</span><span>Gift bags</span>
        </div>
        </div>
      </div>

      <div class="pictures">
        <div class="pictures-item">
          <img src="@/assets/img/care-01.jpg" alt="" />
        </div>
        <div class="pictures-item">
          <img src="@/assets/img/care-02.jpg" alt="" />
        </div>
        <div class="pictures-item">
          <img src="@/assets/img/care-03.jpg" alt="" />
        </div>
        <div class="pictures-item last">
          <img src="@/assets/img/care-04.jpg" alt="" />
        </div>
      </div>

      <div
        class="content reached"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="880"
      >
        <h3>We are reached</h3>

        <div class="box-list">
          <!--box -->
          <div class="box-item">
            <div class="box-item__icon">
              <img src="@/assets/img/house.svg" alt="" />
            </div>
            <div class="box-item__number">1,750</div>
            <div class="box-item__text">
              Impoverished families around the city
            </div>
          </div>
          <!--box -->
          <!--box -->
          <div class="box-item">
            <div class="box-item__icon">
              <img src="@/assets/img/hand.svg" alt="" />
            </div>
            <div class="box-item__number">250</div>
            <div class="box-item__text">
              The deaf, mute, and blind kids/people
            </div>
          </div>
          <!--box -->
          <!--box -->
          <div class="box-item">
            <div class="box-item__icon">
              <img src="@/assets/img/family.svg" alt="" />
            </div>
            <div class="box-item__number">550</div>
            <div class="box-item__text">Orphaned kids from Covid</div>
          </div>
          <!--box -->
        </div>
      </div>

      <div
        class="impact-story"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="880"

        id="impact"
      >
        <div id="impact" class="impact-story__col">
          <h2>OUR IMPACT</h2>
          <p>2,550 emergency packages for covid-19 relief delivered</p>
        </div>

        <div class="impact-story__col last">
          <ul>
            <li>
              <label>17.08.2021</label> <span>Batch 1 (200 boxes totally)</span>
            </li>
            <li>
              <label>21.08.2021 - 23.08.2021</label>
              <span>Batch 2 (1200 boxes totally)</span>
            </li>
            <li>
              <label>02.09.2021 - 30.09.2021</label>
              <span>Batch 3 (600 boxes totally)</span>
            </li>
            <li>
              <label>17.08.2021</label> <span>Batch 4 (550 boxes totally)</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "Impact",

  setup() {
    AOS.init();
  },
};
</script>

<style lang="scss" scoped>
.impact {
  margin-top: -110px;
  overflow: hidden;
  padding: 90px 0 40px;
  text-align: center;

  .content {
    position: relative;

    &.funded {
      color: #fff;

      * {
        position: relative;
        z-index: 10;
      }

      h2 {
        color: #f9ec2e;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        line-height: 15px;
        margin: 5px 0;
      }

      .currency {
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        margin-right: 5px;
      }

      p {
        line-height: 1.4;
      }

      .number {
        font-size: 34px;
        font-weight: 600;
      }

      .total {
        border-bottom: 1px solid #fff;
        margin-bottom: 20px;
        padding: 20px 0;

        &.last {
          border-bottom: 0;

          span {
            display: block;
            font-size: 34px;
            font-weight: 600;
            line-height: 33px;
            margin-bottom: 10px;
            text-transform: uppercase;

            &:nth-child(2) {
              font-size: 18px;
              font-weight: 500;
              letter-spacing: .02em;
              line-height: 14px;
              text-align: center;
              text-transform: uppercase;
            }
          }
        }
      }

      &::before {
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-name: floating;
        animation-timing-function: ease-in-out;
        background: #ff384b;
        border-radius: 100%;
        box-shadow: -20px 0 40px #ff384b;
        content: '';
        display: block;
        filter: blur(53.4993px);
        height: 390px;
        left: -30px;
        position: absolute;
        right: auto;
        top: 5px;
        width: 390px;
        z-index: 1;
      }

      &::after {
        background: rgba(249, 236, 46, .8);
        border-radius: 100%;
        content: '';
        display: block;
        filter: blur(25.476px);
        height: 261px;
        left: -120px;
        position: absolute;
        top: 240px;
        width: 261px;
        z-index: 1;
        z-index: 0;
      }
    }

    &.reached {
      padding-top: 100px;

      h3 {
        font-size: 26px;
        font-weight: 500;
        margin-bottom: 40px;
      }

      * {
        position: relative;
        z-index: 10;
      }

      &::after {
        background: rgba(249, 236, 46, .8);
        border-radius: 100%;
        bottom: -380px;
        content: '';
        display: block;
        filter: blur(130.476px);
        height: 561px;
        position: absolute;
        right: -220px;
        width: 561px;
        z-index: 1;
        z-index: 0;
      }
    }

    &-inner {
      transform: translateY(80px);
    }
  }

  .pictures {
    display: none;
  }

  .box {
    &-list {
      box-sizing: border-box;
      overflow: hidden;
    }

    &-item {
      border: 2px solid $red;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 30px;
      padding: 40px 20px;

      &__number {
        color: $light-red;
        font-size: 44px;
        font-weight: 600;
        margin: 25px 0 20px;
      }

      &__text {
        line-height: 1.5;
      }
    }
  }

  &-story {
    position: relative;
    text-align: left;
    z-index: 4;
    padding-top: 100px;

    &__col {
      padding-bottom: 30px;

      h2 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 0;

        + p {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.4;
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          border-bottom: 1px solid #000;
          margin: 5px 0 10px;
          padding: 10px 0;

          > label {
            display: block;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .impact {
    .content {
      margin: 0 auto;
      padding: 0 40px;
      width: 520px;

      &.funded {
        &::before {
          height: 520px;
          left: 10px;
          top: 10px;
          width: 520px;
        }
      }

      &.reached {
        margin-top: 40%;
      }

      &-inner {
        transform: translateY(55%);
      }
    }

    &-story {
      padding-top: 80px;
    }
  }
}

@media screen and (min-width: 992px) {
  .impact {
    margin-top: 0;
    padding: 180px 20px 0;
    position: relative;

    .content {
      &.funded {
        h2 {
          font-size: 30px;
          line-height: 24px;
        }

        &:after {
          filter: blur(100.476px);
          height: 381px;
          left: -100%;
          width: 381px;
        }

        .currency {
          font-size: 23px;
          line-height: 29px;
        }
      }

      &.reached {
        width: 90%;

        &:after {
          display: none;
        }

        h3 {
          font-size: 35px;
          font-weight: 500;
          line-height: 37px;
        }

        .box-item {
          &__icon {
            transform: scale(1.36) translateY(10px);
          }

          &__number {
            font-size: 75px;
            line-height: 80px;
            margin: 45px 0 20px;
          }

          &__text {
            font-size: 18px;
            line-height: 24px;
            padding: 0 30px;
          }
        }
      }

      &::before {
        top: -50px;
      }
    }

    .box {
      &-list {
        display: flex;
        justify-content: space-around;
        overflow: visible;
      }

      &-item {
        align-items: center;
        background: #fff;
        border-color: transparent;
        box-shadow: 0 0 0 rgb(225 127 136 / 30%) &__icon {
          min-height: 62px;
        };
        justify-content: flex-start;
        padding-top: 50px;
        transition: all 500ms ease;
        width: 30%;

        &__text {
          min-height: 60px;
        }

        &:hover,
        &:focus,
        &:active {
          border-color: $red;
          box-shadow: 0 5px 30px rgb(225 127 136 / 30%);
        }
      }
    }

    .pictures {
      display: block;

      &-item {
        position: absolute;

        img {
          display: block;
          width: 100%;
        }

        &:nth-child(1) {
          left: 0;
          max-width: 390px;
          top: 0;
          width: 100%;
        }

        &:nth-child(2) {
          max-width: 228px;
          right: 50px;
          top: 80px;
          width: 100%;
        }

        &:nth-child(3) {
          left: 40px;
          max-width: 200px;
          top: 25%;
          width: 100%;
        }

        &:nth-child(4) {
          max-width: 340px;
          right: 0;
          top: 25%;
          width: 100%;
        }
      }
    }

    &-story {
      display: flex;
      padding-top: 140px;

      &__col {
        width: 45%;

        &.last {
          margin-left: auto;
        }

        ul {
          > li {
            display: flex;
            justify-content: space-between;
            padding-right: 10px;

            label {
              display: block;
              flex: 1;
            }

            span {
              display: block;
              flex: 1;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1366px) {
  .impact {
    .content {
      width: 680px;

      &.funded {
        padding-top: 0;

        &.reached {
          margin-top: 160px;
          width: 70%;
        }

        &::before {
          height: 720px;
          left: 50%;
          margin-left: -360px;
          right: auto;
          top: -50px;
          width: 720px;
        }

        &:after {
          height: 500px;
          left: -80%;
          top: 360px;
          width: 500px;
        }

        .number {
          font-size: 62px;
        }

        .total {
          &.last {
            border-bottom: 0;

            span {
              font-size: 57px;
              line-height: 52px;
              margin-top: 20px;

              &:nth-child(2) {
                font-size: 25.0606px;
                letter-spacing: .02em;
                line-height: 23px;
              }
            }
          }
        }
      }
    }

    &::after {
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-name: floating-6c7bd076;
      animation-timing-function: ease-in-out;
      background: rgba(249, 236, 46, .8);
      border-radius: 100%;
      bottom: 250px;
      box-shadow: -20px 0 40px rgba(249, 236, 46, .8);
      content: '';
      display: block;
      filter: blur(53.4993px);
      height: 572px;
      position: absolute;
      right: -300px;
      width: 572px;
      z-index: 1;
    }

    &-story {
      &__col {
        h2 {
          font-size: 60px;
          line-height: 85px;
        }
      }
    }
  }
}

.floating {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: floating;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

@media screen and (min-width: 1600px) {
  .impact {
    &-story {
      &__col {
        ul {
          > li {
            label {
              flex: 1.5;
            }

            span {
              flex: 1;
            }
          }
        }
      }
    }

    .pictures {
      &-item {
        &:first-child {
          max-width: 520px;
        }

        &:nth-child(2) {
          max-width: 300px;
        }

        &:nth-child(3) {
          max-width: 270px;
          width: 100%;
        }

        &:last-child {
          max-width: 400px;
        }
      }
    }
  }
}



</style>
